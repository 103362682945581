import React from 'react'
import Navbar from './components/Navbar';
import UserList from './components/UserList';
import GlobalStyle from './utils/GlobalStyle';
import Footer from './components/Footer';

const App = () => (
  <>
    <GlobalStyle />
    <Navbar />
    <UserList />
    <Footer />
  </>
)

export default App;