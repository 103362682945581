import React from "react";
import styled from "styled-components";

import instagramIcon from "../../../images/instagram.svg";
import tiktokIcon from "../../../images/tiktok.svg";
import twitterIcon from "../../../images/twitter.svg";
import youtubeIcon from "../../../images/youtube.svg";
import instagramBlackIcon from "../../../images/instagram-black.svg";
import tiktokBlackIcon from "../../../images/tiktok-black.svg";
import twitterBlackIcon from "../../../images/twitter-black.svg";
import youtubeBlackIcon from "../../../images/youtube-black.svg";
import facebookBlackIcon from "../../../images/facebook-icon.svg"
import { getUserImage } from "../../../utils/getUserImage";

const Main = styled.div`
  width: 220px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImageWithNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
const ImageWrapper = styled.div`
  width: 109px;
  height: 109px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #fe0000, #1eaecd) border-box;
  border: 2px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 97.5px;
    height: 97.5px;
    border-radius: 50%;
  }
`;

const Name = styled.h4`
  color: #030301;
  line-height: 27px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
`;

const StyledButton = styled.button`
  background: rgba(195, 195, 195, 0.005);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 121px;
  height: 36px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 1px;
  color: #000000;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const SocialMediaWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-evenly;
`;

const SocialMediaButton = styled.div`
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  & > img {
    height: 13px;
  }

  &:hover {
    transform: scale(1.1);

    & > img {
      transform: scale(1.1);
    }
  }
`;

const SocialMediaButtonDisabled = styled.div`
  cursor: not-allowed;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    height: 13px;
  }
`;

const UserDetails = ({ user }) => {
  const { userName, instagram, youtube, twitter, tiktok, drive, facebook } = user;

  return (
    <Main>
      <ImageWithNameWrapper>
        <ImageWrapper>
          <img src={getUserImage(userName)} alt="user-image" />
        </ImageWrapper>
        <Name>{userName}</Name>
      </ImageWithNameWrapper>
      <SocialMediaWrapper>
        {youtube ? (
          <a href={youtube} target="_blank">
            <SocialMediaButton>
              <img src={youtubeIcon} alt="youtube" title="Youtube" />
            </SocialMediaButton>
          </a>
        ) : (
          <SocialMediaButtonDisabled>
            <img src={youtubeBlackIcon} alt="youtube" title="Nie podano" />
          </SocialMediaButtonDisabled>
        )}
        {instagram ? (
          <a href={instagram} target="_blank">
            <SocialMediaButton>
              <img src={instagramIcon} alt="instagram" title="Instagram" />
            </SocialMediaButton>
          </a>
        ) : (
          <SocialMediaButtonDisabled>
            <img src={instagramBlackIcon} alt="instagram" title="Nie podano" />
          </SocialMediaButtonDisabled>
        )}
        {twitter ? (
          <a href={twitter} target="_blank">
            <SocialMediaButton>
              <img src={twitterIcon} alt="twitter" title="Twitter" />
            </SocialMediaButton>
          </a>
        ) : (
          <SocialMediaButtonDisabled>
            <img src={twitterBlackIcon} alt="twitter" title="Nie podano" />
          </SocialMediaButtonDisabled>
        )}
        {tiktok ? (
          <a href={tiktok} target="_blank">
            <SocialMediaButton>
              <img src={tiktokIcon} alt="tiktok" title="Tiktok" />
            </SocialMediaButton>
          </a>
        ) : (
          <SocialMediaButtonDisabled>
            <img src={tiktokBlackIcon} alt="tiktok" title="Nie podano" />
          </SocialMediaButtonDisabled>
        )}


        {facebook && (
           <a href={facebook} target="_blank">
           <SocialMediaButton>
             <img src={facebookBlackIcon} alt="tiktok" title="Tiktok" />
           </SocialMediaButton>
         </a>



        )}



      </SocialMediaWrapper>
      {
        drive && (
          <a href={drive} target="_blank">
            <StyledButton>Statystyki</StyledButton>
          </a>
        )
        // : <DisabledButton>Statystyki</DisabledButton>
      }
    </Main>
  );
};

export default UserDetails;
