import React from "react";
import styled from "styled-components";
import UserDetails from "./UserDetails";
import users from "./users.json";

const Main = styled.div`
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Header = styled.div`
  width: 80%;
  text-align: center;
  margin: 50px 0;
  display: flex;
  gap: 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    font-size: 32px;
    color: #030301;

    @media (max-width: 500px) {
      font-size: 26px;
    }
  }

  & > p {
    color: #030301;
    font-size: 23px;

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
`;

const Contact = styled.div`
  width: 100%;
  padding: 50px 0;
  background: rgb(245, 245, 245);
  margin: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 30px 0;
  align-items: center;
  text-align: center;

  & h4 {
    color: #030301;
    font-size: 26px;

    @media (max-width: 500px) {
      font-size: 22px;
    }
  }

  & p {
    color: #030301;
    font-size: 22px;

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

const UserList = () => (
  <Main>
    <Contact id="kontakt">
      <TextWrapper>
        <h4>INFLUENCERZY MyMusic</h4>
        <h4>Kontakt reklamowy:</h4>
      </TextWrapper>
      <TextWrapper>
        <p>Witold Mikołajczyk</p>
        <p>Specjalista ds. reklamy</p>
        <p>Tel: 795 590 543</p>
      </TextWrapper>
      <TextWrapper>
        <p>MYMUSIC sp. j.</p>
        <p>ul. Wierzbowa 31</p>
        <p>62-081 Wysogotowo</p>
        <p>NIP: 7811921571</p>
      </TextWrapper>
    </Contact>
    <Header id="influencerzy">
      <h1>Wybrane zasięgi influencerów MyMusic</h1>
      <p>Sprawdź ich social media klikając na przyciski pod nazwiskiem!</p>
    </Header>
    <Wrapper>
      {users.map((user) => (
        <UserDetails user={user} key={user.userName} />
      ))}
    </Wrapper>
    <Contact id="pytania">
      <TextWrapper>
        <h4>INFLUENCERZY MyMusic</h4>
      </TextWrapper>
      <TextWrapper>
        <p>Witold Mikołajczyk</p>
        <p>Gdyby statystyki wybranego twórcy się nie wyświetliły,</p>
        <p>
          proszę o kontakt mailowy <b>witold@my-music.pl</b>
        </p>
      </TextWrapper>
      <TextWrapper>
        <p>Jesli wybranego przez Państwa twórcy nie ma na liście -</p>
        <p>także prosimy o kontakt.</p>
      </TextWrapper>
    </Contact>
  </Main>
);

export default UserList;
