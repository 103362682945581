import sylwia from "../images/users/sylwia-lipka.jpg";
import dominik from "../images/users/dominik-lupicki.jpg";
import remo from "../images/users/remigiusz-lupicki.jpg";
import hammerling from "../images/users/mikolaj-hammerling.jpg";
import bartek from "../images/users/bartek-kaszuba.jpg";
import kaja from "../images/users/kaja-jablonska.jpg";
import agata from "../images/users/agata-gladysz.jpg";
import olivia from "../images/users/olivia-klinke.jpg";
import paulina from "../images/users/paulina-twardowska.jpg";
import marianna from "../images/users/marianna-wywarska-szczepaniak.jpg";
import filip from "../images/users/filip-kloda.jpg";
import jazierska from "../images/users/weronika-jazierska.jpg";
import aleksandra from "../images/users/aleksandra-dobek.jpg";
import rupinski from "../images/users/dominik-rupinski.jpg";
import jakub from "../images/users/jakub-rokicki.jpg";
import monika from "../images/users/monika-mankiewicz.jpg";
import soundngrace from "../images/users/soundngrace.jpg";
import kamerzysta from "../images/users/kamerzysta.jpg";
import wiola from "../images/users/wiola-kotelecka.jpg";
import hubert from "../images/users/hubert-wezka.jpg";
import mariusz from "../images/users/mariusz-slonski.jpg";
import mymusic from "../images/users/mymusic.jpg";
import mrozicka from "../images/users/karolina-mrozicka.jpg";
import kaminski from "../images/users/olivier-kaminski.jpg";
import avenix from "../images/users/adam-kedziora.jpg";
import ada from "../images/users/ada-borkowska.jpg";
import andres from "../images/users/dawid-andres.jpg"

export const getUserImage = (userName) => {
  switch (userName) {
    case "Ada Borkowska":
      return ada;
    case "Karolina Mrozicka":
      return mrozicka;
     case "Dawid Andres":
        return andres;
    case "Oliver Kamiński":
      return kaminski;
    case "Adam Avenix Kędziora":
      return avenix;
    case "Dominik Łupicki":
      return dominik;
    case "Sylwia Lipka":
      return sylwia;
    case "Remigiusz Remo Łupicki":
      return remo;
    case "Mikołaj Hammerling":
      return hammerling;
    case "Bartek Kaszuba":
      return bartek;
    case "Kaja Jabłońska":
      return kaja;
    case "Agata Gładysz":
      return agata;
    case "Olivia Klinke":
      return olivia;
    case "Paulina Twardowska":
      return paulina;
    case "Marianna Wywarska-Szczepaniak":
      return marianna;
    case "Weronika Vercious Jazierska":
      return jazierska;
    case "Aleksandra Dobek":
      return aleksandra;
    case "Jakub Merghani Rokicki":
      return jakub;
    case "Monika Mankiewicz":
      return monika;
    case "Sound'n'Grace":
      return soundngrace;
    case "Kamerzysta":
      return kamerzysta;
    case "Wiola Kotelecka":
      return wiola;
    case "Hubert Węzka":
      return hubert;
    case "Mariusz Słoński":
      return mariusz;
    case "MyMusic":
      return mymusic;
    default:
      return sylwia;
  }
};
