import React from "react";
import styled from "styled-components";
import MyMusicLogo from "../../images/MyMusic.svg";
import instagramIcon from "../../images/instagram-gray.svg";
import twitterIcon from "../../images/twitter-gray.svg";
import youtubeIcon from "../../images/youtube-gray.svg";

const Main = styled.div`
  width: 100%;
  height: 80px;
  background: rgb(245, 245, 245);
  box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid rgb(240, 241, 242);
`;

const Wrapper = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    height: 18px;
  }

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const SocialMediaWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;

  & > a > img {
    height: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Footer = () => (
  <Main>
    <Wrapper>
      <img src={MyMusicLogo} alt="My Music" />
      <SocialMediaWrapper>
        <a
          href="https://www.youtube.com/channel/UCHeo8472OnB2poQyPvcBx9g"
          target="_blank"
        >
          <img src={instagramIcon} alt="instagram" title="Instagram" />
        </a>
        <a href="https://twitter.com/mymusicpl" target="_blank">
          <img src={twitterIcon} alt="twitter" title="Twitter" />
        </a>
        <a href="https://twitter.com/mymusicpl" target="_blank">
          <img src={youtubeIcon} alt="youtube" title="Youtube" />
        </a>
      </SocialMediaWrapper>
    </Wrapper>
  </Main>
);

export default Footer;
