import React, { useState } from "react";
import styled from "styled-components";

const Main = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 2px solid rgb(240, 241, 242);
  background: rgb(245, 245, 245);
  box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
`;

const Wrapper = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Logo = styled.h4`
  color: #4e525f;
  font-size: 18px;
  letter-spacing: 1px;
`;

const NavWrapper = styled.div`
  height: 100%;
  gap: 0 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > a {
    color: #4e525f;
    font-size: 15px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: all 0.1s;

    &:hover {
      color: #030301;
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuButton = styled.div`
  width: 30px;
  height: 23px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.1s;
  cursor: pointer;

  &:active {
    & > div {
      background: #030301;
    }
  }

  & > div {
    width: 100%;
    height: 5px;
    background: #4e525f;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MenuMain = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  display: none;

  @media (max-width: 768px) {
    display: ${(props) => (props.isMenuOpen ? "flex" : "none")};
  }
`;

const MenuWrapper = styled.div`
  width: 300px;
  height: 100%;
  border-left: 2px solid rgb(240, 241, 242);
  box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
  background: rgb(245, 245, 245);
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 425px) {
    width: 100%;
  }
`;

const MobileNavWrapper = styled.div`
  height: 200px;
  margin: 30px 0;
  gap: 26px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > a {
    color: #4e525f;
    font-size: 15px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: all 0.1s;

    &:hover {
      color: #030301;
      font-weight: 500;
    }
  }
`;

const Blank = styled.div`
  width: 100%;

  @media (max-width: 425px) {
    width: 0px;
  }
`;

const Navbar = () => {
  const [isMenuOpen, toggleMenuOpen] = useState(false);

  return (
    <>
      <Main>
        <Wrapper>
          <Logo>MMInflu Agency.</Logo>
          <NavWrapper>
            <a href="#kontakt">Kontakt</a>
            <a href="#influencerzy">Influencerzy</a>
            <a href="#pytania">Pytania i problemy</a>
          </NavWrapper>
          <MenuButton onClick={() => toggleMenuOpen((prev) => !prev)}>
            <div />
            <div />
            <div />
          </MenuButton>
        </Wrapper>
      </Main>
      <MenuMain isMenuOpen={isMenuOpen}>
        <Blank onClick={() => toggleMenuOpen(false)} />
        <MenuWrapper>
          <MobileNavWrapper>
            <a href="#kontakt" onClick={() => toggleMenuOpen(false)}>
              Kontakt
            </a>
            <a href="#influencerzy" onClick={() => toggleMenuOpen(false)}>
              Influencerzy
            </a>
            <a href="#pytania" onClick={() => toggleMenuOpen(false)}>
              Pytania i problemy
            </a>
          </MobileNavWrapper>
        </MenuWrapper>
      </MenuMain>
    </>
  );
};

export default Navbar;
